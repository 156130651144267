import styled from '@emotion/styled';
import {
  FALLBACK_COLOR,
  BANNER_OPACITY,
  BODY_COLOR_OPACITY,
} from '@/components/DesignSystem/ModernTheme/utils/common';
export const HeroBannerVideoBackground = styled.div`
  background: ${(props) =>
    !props?.propsBackground?.content[0]?.url && `none, ${props?.themeBackground}`};
  background-size: cover;
  background-position: center;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0; /* Set the z-index behind other content */
  }
  /* Add a linear gradient overlay */
  &::after {
    content: '';
    background: ${(props) => props?.themeBackground};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${BANNER_OPACITY};
    z-index: 1;
  }
  @media screen and (min-width: 768px) {
    & > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* Scale the video while maintaining its aspect ratio */
    }
  }
  @media screen and (max-width: 767px) {
    & > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* Scale the video while maintaining its aspect ratio */
    }
  }
  & > video {
    position: absolute;
    height: 100%;
  }
`;

export const HeroBannerParent = styled.div`
  &.bg_type_image {
    background: ${(props) =>
      !props?.propsBackground?.content[0]?.url && `none, ${props?.themeBackground}`};
    background-size: cover;
    background-position: center;
    & > .bg {
      position: absolute;
      height: 100%;
      object-fit: fill;
      width: 100%;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0; /* Set the z-index behind other content */
    }
    /* Add a linear gradient overlay */
    &::after {
      content: '';
      background: ${(props) => props?.themeBackground};
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: ${BANNER_OPACITY};
      z-index: 1;
    }
  }

  &.bg_type_solid {
    background: ${(props) => props?.themeBackground};
  }

  .bg_type_solid {
    content: '';
    background: ${(props) => props?.themeBackground};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${BANNER_OPACITY};
    z-index: 1;
  }
`;
export const BannerContainer = styled.div`
  display: flex;
  width: 100%;
  z-index: 3;
  position: relative;

  @media screen and (min-width: 768px) {
    gap: 3.25rem;
    padding: 4.75rem 0rem 4.75rem 8.25rem;
  }
  @media screen and (max-width: 767px) {
    padding: 2rem 0 0 0;
    flex-direction: column;
    gap: 1.25rem;
  }
`;
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  @media screen and (min-width: 768px) {
    gap: 2.625rem;
    width: 50%;
  }
  @media screen and (max-width: 767px) {
    gap: 1.5rem;
    padding: 0 1.25rem;
    width: 100%;
  }
`;
export const RightContainer = styled.div`
  display: flex;
  align-self: center;
  aspect-ratio: 4 / 3;
  background-color: ${(props) => (props?.IS_MEDIA_URL ? 'transparent' : FALLBACK_COLOR)};

  & > .slider__parent,
  & > .slider__parent > .slick-slider,
  & > .slider__parent > .slick-slider > .slick-list,
  & > .slider__parent > .slick-slider > .slick-list > .slick-track,
  & > .slider__parent > .slick-slider > .slick-list > .slick-track > .slick-slide,
  & > .slider__parent > .slick-slider > .slick-list > .slick-track > .slick-slide > div,
  &
    > .slider__parent
    > .slick-slider
    > .slick-list
    > .slick-track
    > .slick-slide
    > div
    > .slider-media,
  &
    > .slider__parent
    > .slick-slider
    > .slick-list
    > .slick-track
    > .slick-slide
    > div
    > .slider-media
    > .slide-image-container,
  &
    > .slider__parent
    > .slick-slider
    > .slick-list
    > .slick-track
    > .slick-slide
    > div
    > .slider-media
    > .slide-image-container
    > img {
    height: 100% !important;
  }
  @media screen and (min-width: 768px) {
    width: 50%;
    & > .slider__parent > .slick-slider > .slick-dots {
      bottom: -1rem !important;
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    & > .slider__parent > .slick-slider > .slick-dots {
      bottom: -0.625rem !important;
    }
  }
`;
export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  align-self: flex-start;
  & > h1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    color: ${(props) => props?.textStyles?.heading?.color};
    font-family: ${(props) => props?.textStyles?.heading?.fontFamily || 'Arial'};
    text-align: ${(props) => props?.textStyles?.heading?.textAlign || 'left'};
    overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
    overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
  }
  & > p {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    font-style: normal;
    margin: 0;
    color: ${(props) => props?.textStyles?.body?.color};
    text-align: ${(props) => props?.textStyles?.body?.textAlign || 'left'};
    font-family: ${(props) => props?.textStyles?.body?.fontFamily || 'Arial'};
    opacity: ${BODY_COLOR_OPACITY};
    overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
    overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
  }
  @media screen and (min-width: 768px) {
    gap: 1.5rem;
    & > h1 {
      font-size: ${(props) => props?.textStyles?.heading?.fontSize || '4.5rem'};
      font-weight: ${(props) => props?.textStyles?.heading?.fontWeight || '500'};
      line-height: 4.938rem;
    }
    & > p {
      font-size: ${(props) => props?.textStyles?.body?.fontSize || '1.25rem'};
      font-weight: ${(props) => props?.textStyles?.body?.fontWeight || '400'};
      line-height: 1.75rem;
    }
  }
  @media screen and (max-width: 767px) {
    gap: 0.75rem;
    & > h1 {
      font-size: 2rem;
      font-weight: 400;
      line-height: normal;
    }
    & > p {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: normal;
    }
  }
`;
export const BottomSection = styled.div`
  display: flex;
  width: 100%;
  align-self: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    button {
      width: 100%;
    }
  }
`;
